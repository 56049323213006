.month-switcher {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2em; /* 較大的字體大小 */
    margin: 5px 0; /* 上下邊距 */
}

.month-switcher button {
    font-size: 1em;/* 箭頭大小 */
    min-width: 44px; /* 設置最小寬度 */
    background: none; /* 透明背景 */
    border: none; /* 無邊框 */
    cursor: pointer;
    color: #000; /* 文本顏色 */
    padding: 0 10px; /* 按鈕的內邊距 */
    font-size: 24px; /* 字體大小，可根據需要調整 */
}

.month-switcher button:hover {
    background-color: #e6e6e6; /* 懸停時的背景顏色 */
}


.month-switcher span {
    margin: 0 15x; /* 月份的邊距 */
}
